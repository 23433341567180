.price {
  padding-right: 10px;
}

.qty {
  font-size: 15px;
  color: var(--ion-color-medium-tint);
}

.totalText {
  font-size: 15px;
  padding: 0 5px;
}

.dollarSign {
  font-size: 15px;
  color: var(--adm-color-primary);
}

.totalSum {
  font-size: 25px;
  color: var(--adm-color-primary)
}