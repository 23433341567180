.cardContainer {
  // height: 100%;
  background-color: var(--app-menu-text-color);
  margin: 8.5px 10px 0 10px;
  border-radius: 10px;
  padding: 10px;
}

.cardTitleContainer {
  display: flex;
  justify-content: space-between;
}

.cardTitleWithArrow {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.deleteIcon {
  height: 20px;
  width: 20px;
}