.uniformContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  text-align: center;
}

.mainUniformPic {
  width: 100%;
  height: 100%;
}

.collapseContainer {
  :global(.antd-mobile-icon) {
      color: var(--adm-color-primary);
  }

}

.hintContainer {
  display: flex;
  align-items: center;
  background-color: rgba(255, 249, 237, 1);
  padding: 5px;
}

.noNeed {
  :global(.adm-list-item-content-main) {
    padding: 0;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;

    :global(.adm-form-item-child),
    :global(.adm-form-item-child-position-normal) {
      width: 100%;
    }

    :global(.adm-list-item) {
      padding: 0;
    }

    :global(.adm-list) {
      padding: 0 0 0 0;
    }

    :global(.adm-list.adm-list) {
      padding: 0 0 0 0;
    }
  }
}