.uniformPageContainer {
  width: 100%;
  height: 100%;
}

.uniformEducationTabsContainer {
  width: "100%";
  background-color: var(--app-secondary-background-color);
}

.uniformFilterTag {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Align items horizontally to the start */
  padding: 0.2vw 0.8vw;

  svg {
    margin-left: 0.1vw;
    height: 18px;
    width: 18px
  }
}

.uniformSetChoiceContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}