.tickGlobalModalContainer {
  :global(.ant-modal-content) {
    background-color: transparent;
    box-shadow: none;
    // padding: unset;
    width: 30px
  }
}
.container {
  width: 100%;
  background: var(--tick-color-default-bg);
}

.defaultBgColor {
  background: var(--tick-color-default-bg);
}

.baseContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 30000;
  border-radius: 10px;
}

.successContainer {
  @extend .baseContainer;
}

.loadingContainer {
  @extend .baseContainer;
}

.warningContainer {
  @extend .baseContainer;
}
.reminderContainer {
  @extend .baseContainer;
}

.closeContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 200px;
  height: 200px;
  padding: 16px;
  text-align: right;
}

.icon {
  margin-bottom: 10px;
}

.localModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: translateX(100%);
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.slide-in {
  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.slide-out {
  animation-name: slideOut;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

@media (max-width: 767px) {
  :global(.ant-modal-root .localModal) {
    max-width: 100vw;
    margin: unset;
  }
}

.text {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding: 0px 16px;
  text-align: center;
  margin: 20px 0px;
}

.btn {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  &:hover {
    color: white !important;
    background: transparent !important;
  }
}
