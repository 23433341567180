.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.shopImg {
    width: 100%;
    height: 250px;
}

.shopAddress {
    width: 100%;
    height: 200px;
    padding: 0 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    font-size: 2.3vh;
}