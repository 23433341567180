/* All the native style of html will be update in here  */

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;700&family=Roboto:wght@400;700&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Noto Sans TC", sans-serif;
}

html,
body {
  background-color: rgb(213, 213, 213);
  overflow-y: scroll;
}
