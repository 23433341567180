.playgroundContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start; /* Align items vertically in the center */
  justify-content: flex-start; /* Align items horizontally to the start */
  flex-flow: row wrap; 
  overflow-x: hidden;
}

.menu {
  border: 0;
}

.menu:active:before {
  opacity: 0;
}