.listItemContainer {
    width: 100%;
    display: flex;
}

.listItem {
    flex: 1;
}

.btn {
    border: none;
}