.arrowButton {
  border: 0;
  border-bottom: 1px var(--adm-color-border) solid;
  background-color: var(--app-secondary-background-color);
  width: 100%;
}

.arrowButtonContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2vw 0 0.2vw 0;
    
  * {
    font-size: 1.5vh;
    font-weight: normal;
    color: var(--adm-color-border)
  }

  p {
    color: var(--adm-color-text-secondary)
  }
}

.customArrowButton {
  border: 0;
  border-bottom: 1px var(--adm-color-border) solid;
  background-color: var(--app-secondary-background-color);
  width: 100%;
  padding: 7px 12px;
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }

  p {
    color: var(--app-text-color);
    font-size: 1.9vh;
    opacity: 0.8;
  }
}

.customElement {
  display: flex;
  justify-content:space-between;
}