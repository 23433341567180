.container {
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
  padding: 0 10px 12px 10px;
}

.method {
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
}

.card {
    margin-top: 12px;
}

.paymentDetail {
    width: 100%;
    height: 50px;
    font-size: 2vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dollarSign {
    font-size: 10px;
}

.lowerContainer {
    position: absolute;
    bottom: 0;
    height: 55px;
    width: 100%;
    background-color: var(--app-secondary-background-color);
    padding: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.soldOutReminderModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .modalHeader {
        margin-bottom: 10px;
    }
    .modalContent {
        font-size: 1.5vh;
        color: var(--adm-color-primary);
    }
}

.amount {
    font-size: 2vh;
}