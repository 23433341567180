.uniformFilterContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-flow: column wrap;
  align-items: start; /* Align items vertically in the center */
}

.completeButton {
  color: var(--adm-color-primary);
  cursor: pointer;
}