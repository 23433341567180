:root {
  
  --adm-color-primary: #ff6600;
  --adm-color-success: #52c41a;
  --adm-color-warning: #fadb14;
  --adm-color-danger: #f5222d;
  --adm-color-border: #e4e4e4;
  --adm-color-text-secondary: #333333;

  --adm-nav-bar-background-color: #fafbfc;
  --adm-nav-bar-text-color: #333333;

  --adm-tab-bar-background-color: #fafbfc;
  --adm-tab-bar-text-color: #333333;
  --adm-tab-bar-text-secondary-color: #ff6600;

  --app-background-color: #efefef;
  --app-background-mask: #000000;
  --app-text-color: #333333;
  --app-text-secondary-color: #999999;
  --app-payment-success: #09b46c;
  --app-payment-failure: #e82e3d;
  --app-payment-cancel: #acacac;
  --app-secondary-background-color: #ffffff;
  --app-input-background-color: #ffffff;

  --adm-tag-text-color: #ffffff;

  --app-block-title-background-color: #efefef;
  --app-block-title-text-color: #999999;
  --app-block-content-background-color: #ffffff;

  --app-menu-background-color: #781819;
  --app-menu-text-color: #ffffff;

  --app-gender-male-color: #1677ff;
  --app-gender-female-color: #ff6600;

  --app-gender-selector-male-text-color: #1677ff;
  --app-gender-selector-female-text-color: #ff6600;
  --app-gender-selector-male-background-color: #e7f1ff;
  --app-gender-selector-female-background-color: #ffefef;

  --app-season-summer-color: #ff6600;
  --app-season-winter-color: #1677ff;

  --adm-popup-z-index: 3000;
  --adm-modal-z-index: 3000;
  --adm-mask-z-index: 3000;

  --app-uniform-set-box-shadow: 0 3px 5px 0 rgba(0,0,0,0.2);
  --app-uniform-set-box-shadow-hover: 0 3px 5px 0 rgba(0,0,0,0.5);
  
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--tick-color-red-dark);
  --ion-color-primary-tint: var(--tick-color-red-dark);

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** custom **/
  --tick-color-bg: #ebeaef;
  --tick-color-collapse-bar: #f6f6f8;
  --tick-color-progress-indicators: #d6d6d7;
  --tick-color-blue: #1c63b7;
  --tick-color-green: #00a650;
  --tick-color-green-dark: #00a800;
  --tick-color-orange: #f19100;
  --tick-color-black: #000000;
  --tick-color-white: #ffffff;
  --tick-color-modal-overlay: rgba(0, 0, 0, 0.5);
  --tick-color-modal-overlay-success: #00000020;
  --tick-color-text-collapse-bar: #7e8993;
  --tick-color-text-desc: #6b6b6b;
  --tick-color-status-bg: #f1f8ee;

  --tick-color-default-bg: #dbdbdb;
  --tick-border-color-grey: #efefef;

  --tick-color-yellow: #f9c80e;

  --tick-color-red: #600;
  --tick-color-red-dark: #700;
  --tick-color-grey: 9C9C9C;
  --tick-color-red-light: red;

  --tick-main-theme: var(--tick-color-red);

  // custom font style
  --tick-font-size-title: 16px;
  --tick-font-size-header: 10px;
  --tick-font-size-content: 14px;
  --tick-font-size-desc: 10px;

  --tick-font-weight-normal: 400;
  --tick-font-weight-spacial: 500;
  --tick-font-weight-contentTitle: 600;
  --tick-font-weight-navTitle: 700;

  --tick-doc-height: 100%;
  --tick-maximum-mobile-width: 768px;
  --tick-minimum-mobile-width: 300px;
}
