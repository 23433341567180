.container {
  // background-image: url("../../assets/images/loginBG.jpg");
  // background-size: cover;
  // background-position: center;
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  img {
    width: 45vh;
    height: 26vh;
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    height: 26vh;
    width: 100%;
  }

  .progressBarContainer {
    width: 100%;
    display: flex;
  }

}

@media (max-width: 768px) {
  .container {
    img {
      width: 31vh;
      height: 16vh;
    }
  }
}
