.shoppingBagContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start; /* Align items vertically in the center */
    justify-content: flex-start; /* Align items horizontally to the start */
    flex-flow: row wrap;
}

.upperContainer {
    height: calc(100% - 50px);
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
        width: 0;  /* 将滚动条宽度设置为0 */
        background: transparent;  /* 设置滚动条背景为透明 */
    }
}

.lowerContainer {
  position: absolute;
  bottom: 50px;
  height: 55px;
  width: 100%;
  background-color: var(--app-secondary-background-color);
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: solid 1px var(--adm-color-border);
}