.container {
  background: #c3c3c3;
  height: 100vh;
  padding: 0px 16px;
  width: 100%;

  .title {
    display: flex;
    justify-content: center;
    padding: 8px 0px;
  }

  .content {
    :global(.ant-row) {
      div {
        margin: 4px 0px;
      }
    }
    :global(.ant-btn-primary) {
      width: 200px;
    }
  }
}
