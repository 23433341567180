.shoppingBagQty {
  position: absolute;
  top: 3px;
  right: -3px;
  background-color: red;
  border-radius: 15px;
  color: #fff;
  padding: 4px;
  font-size: 10px;
  height: 12px;
  width: 18px;
  justify-content: center;
  display: flex;
  align-items: center;
}