.aboutSystemContainer {
  width: 100%;
  height: 100%;
}

.titleText {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px
}

.versionText {
    font-size: 15px;
    display: flex;
    justify-content: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Firefox */
}

.companyRightText {
    font-size: 15px;
    display: flex;
    justify-content: center;
    padding: 50px 0px
}

.systemInfoContainer {
    border-bottom: 1px var(--adm-color-border) solid;
}