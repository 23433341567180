.cardItemContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.deleteIcon {
  height: 200px;
  width: 200px;
}

.leftImage {
  height: 100px;
  width: 100px;
}

.middleText {
  flex: 1;
  font-size: 18px;
  height: 100px;
  padding: 0 5px;
  font-size: 16px;
}

.upperInfo {
  display: flex;
  height: 25%;
}

.middleInfo {
  height: 25%;
  color: var(--ion-color-medium-tint);
}

.bottomInfo {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // font-size: 25px;
  color: var(--adm-color-primary);
}

.rightIcons {
  max-width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.qty {
  margin-top: 5px;
  height: 20px;
  border: 1px solid var(--adm-color-primary);
  padding: 2px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--adm-color-primary);
}

.deleteIcon {
  height: 20px;
  width: 20px;
}