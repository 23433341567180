.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    padding: 5px;
    font-size: 16px;
    background-color: #F5F5F5;
}

.deliveryMethod {
    display: flex;
    width: 25%;
    align-items: center;
    div {
        margin-left: 5px;
    }
}

.trackingNumber {
    width: 50%;
    color: var(--ion-color-medium-tint);
}

.copy {
    width: 25%;
}

.icon {
    font-size: 1.5vh;
}