.stackContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
  background-color: var(--app-background-color);
  display: block;
  transform: translateX(100%); // Add this line
  
  &.show {
    z-index: 2000;
    transform: translateX(0); // Animate to this position
    transition: transform 0.25s ease-in-out;
  }
  
  .stackBody {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
  // visibility: hidden;
  
  &.show {
    z-index: 1999;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    // visibility: visible;
  }
}
