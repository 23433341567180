/* All the global class will be update in here  */

.app * {
  scrollbar-width: none;
}

.app {
  width: 100%;
  height: var(--tick-doc-height);
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.body {
  flex: 1;
  display: flex;
  justify-content: center;
  // align-items: center;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: none;
  background-color: var(--app-background-color);

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.bottom {
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
}

.localModal {
  max-width: var(--tick-maximum-mobile-width);
  width: 100% !important;
}

.paymentSuccess {
  .adm-result-page-bg {
    background-color: var(--app-payment-success) !important ;
  }
  .adm-result-success .adm-result-icon .antd-mobile-icon {
    color: var(--app-payment-success) !important ;
  }
}

.paymentFailure {
  .adm-result-page-bg {
    background-color: var(--app-payment-failure) !important ;
  }
  .adm-result-success .adm-result-icon .antd-mobile-icon {
    color: var(--app-payment-failure) !important ;
  }
}

.paymentCancel {
  .adm-result-page-bg {
    background-color: var(--app-payment-cancel) !important ;
  }
  .adm-result-success .adm-result-icon .antd-mobile-icon {
    color: var(--app-payment-cancel) !important ;
  }
}

.card {
  margin-bottom: 12px;
  .adm-card-header:not(:last-child) {
      border: none !important;
  }
  .adm-card-body {
      padding-top: 0 !important;
  }
}


.genderSelector {
  .adm-grid {
    // &:nth-child(1) {
    //   color: hsl(215, 100%, 54%) !important;
    // }
    // &:nth-child(2) {
    //   color: var(--adm-color-primary) !important;
    // }
    .adm-selector-item:nth-child(1) {
      color: var(--app-gender-selector-male-text-color) !important;
      background-color: var(--app-gender-selector-male-background-color) !important;

      .adm-selector-check-mark-wrapper {
        border-bottom: solid 8px var(--app-gender-selector-male-text-color);
        border-right: solid 10px var(--app-gender-selector-male-text-color);
      }
    }
    .adm-selector-item:nth-child(2) {
      color: var(--app-gender-selector-female-text-color) !important;
      background-color: var(--app-gender-selector-female-background-color) !important;

      .adm-selector-check-mark-wrapper {
        border-bottom: solid 8px var(--app-gender-selector-female-text-color);
        border-right: solid 10px var(--app-gender-selector-female-text-color);
      }
    }
  }
}

.listCardHeader {
  .adm-card-header-title {
    font-size: 2.1vh !important;
  }
}

// .adm-center-popup-wrap {
//   top: 50%;
//   left: 60%;
//   // transform: translate(-25%, -50%) !important;
// }

.loading {
  // width: 75%;
  // padding-bottom: 19px;
  
  .adm-modal-content {
    display: flex;
    justify-content: center;
    // padding: 50px 0;
    overflow: hidden;
    font-size: 20px;

    // .adm-spin-loading {
    //   width: 80px !important;
    //   height: 80px !important;
    // }
  }
  
}

.checkoutStudentInfo {
  .adm-list-body {
    border-top: none !important;
  }
  .adm-list-item {
    padding-left: 0 !important;
  }
}
