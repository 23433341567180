.container {
    width: 100%;
    height: 100%;
}

.tab {
    width: 100%;
}

.orderStatus {
    color: var(--adm-color-primary);
}

.logisticsInfoContainer {
    margin-top: 10px;
}