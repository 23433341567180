.uniformSetChoice {
  width: 46%;
  height: 20vh;
  padding-bottom: 46%;
  background-color: var(--adm-color-white);
  color: black;
  position: relative;
  box-shadow: var(--app-uniform-set-box-shadow);
  border-radius:15px;
  overflow: hidden;
  cursor: pointer;

  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.uniformSetChoice:hover {
  box-shadow: var(--app-uniform-set-box-shadow-hover);
  transform: translateX(-1px) translateY(-1px);
  transition: all 0.2s ease;
  // -webkit-transition: 0.3s;
}

.text {
  display: flex;
  justify-content: center;
}

.noImageLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 84%;
  flex-flow: column wrap;
  
  img {
    width: 70%;
    height: 40%;
  }

  p {
    color: var(--adm-color-text-secondary);
  }
}

.uniformSetChoice:nth-child(odd) {
  margin: 0 0 2.5% 2.5%
}

.uniformSetChoice:nth-child(even) {
  margin: 0 2.5% 2.5% 0
}

// first two element
.uniformSetChoice:nth-child(-n+2) {
  margin-top: 2.5%;
}