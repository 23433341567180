.mainUniformPic {
  width: 100%;
  height: 250px;
}

.totalSum {
  width: 100%;
  height: 50px;
  margin: 10px 0;
  background-color: var(--app-secondary-background-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
}