.filterTag {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Align items horizontally to the start */
  padding: 0.2vw 0.8vw;
  color: var(--adm-tag-text-color) !important;
  z-index: 1000;
  
  svg {
    cursor: pointer;
    margin-left: 0.1vw;
    height: 18px;
    width: 18px
  }
}