.titleText {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px
}

.collapseContainer {
    :global(.antd-mobile-icon) {
        color: var(--adm-color-primary);
    }

    :global(.adm-collapse-panel-header:hover) {
        color: var(--adm-color-primary);
    }
}