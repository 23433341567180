.menuButton {
  border: 0;
  padding-top: 1.4vh;
  background-color: transparent;
  color: var(--adm-nav-bar-text-color)
}

.menuButton:active:before {
  opacity: 0;
}

.menu {
  background-color: var(--app-menu-background-color);
}

.menuContainer {
  display: block;
  padding-top: 4vw;

  * {
    color: var(--app-menu-text-color);
  }
  
  .menuHeaderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 12vh;
    border-bottom: 1px var(--app-menu-text-color) solid;
    padding: 10px;
    // margin-bottom: 2vw;
    flex-direction: column;
    
    p {
      flex: 1;
      font-size: 2.1vh;
      font-weight: 600;

      &:first-child {
        cursor: pointer;
      }
    }
  }

  .langContainer {
    width: 100%;
    display: flex;
    justify-content:end;
    
    button {
      padding-left: 2vw;
      padding-right: 2vw;
      border: 0;
      width: auto;
      background-color: transparent;
      color: var(--app-menu-text-color);

      span {
        font-size: 1.9vh;
        font-weight: normal;
      }
    }
  }
}