/* All the ant design global style and global class will be update in here  */
.ant-app {
  max-width: var(--tick-maximum-mobile-width); // Default base is 768px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  contain: layout size style;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  z-index: 0;
  // background: #fafbfc;
  background: var(--app-background-color);
  color: var(--adm-color-text-secondary);

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

#color-theme {
  --adm-color-primary: #ff6600;
  --adm-color-success: #52c41a;
  --adm-color-warning: #faad14;
  --adm-color-danger: #f5222d;
  --adm-color-border: #e4e4e4;
  --adm-color-text-secondary: #333333;
  
}

.adm-nav-bar {
  color: var(--adm-nav-bar-text-color);
  background-color: var(--adm-nav-bar-background-color);
}

.adm-tab-bar {
  background-color: var(--adm-tab-bar-background-color);
  
  .adm-tab-bar-item {
    color: var(--adm-tab-bar-text-color) !important;
  }
  
  .adm-tab-bar-item-active {
    color: var(--adm-tab-bar-text-secondary-color) !important;
  }
}


.adm-form {
  background-color: var(--app-background-color);
}

.adm-list-header {
  // color: var(--adm-color-text-secondary) !important;
  background-color: var(--app-background-color);
  font-size: 2vh !important;
}

.adm-list-item {
  background-color: var(--app-input-background-color) !important;
}

.adm-input-element {
  color: var(--adm-color-text-secondary) !important;
}

.adm-list-item-content-main {
  color: var(--adm-color-text-secondary) !important;
  font-size: 1.9vh !important;
}

.adm-result {
  border-radius: 10px;
}

.adm-capsule-tabs-header {
  background-color: var(--app-secondary-background-color);
}

.adm-capsule-tabs-tab {
  color: var(--adm-color-text-secondary) !important;
  background-color: var(--app-input-background-color) !important;
}

.adm-capsule-tabs-tab-active {
  color: var(--adm-color-white) !important;
  background-color: var(--adm-color-primary) !important;
}

.adm-card {
  background-color: var(--app-secondary-background-color) !important;
}

.adm-center-popup {
  --z-index: var(--adm-modal-z-index) !important;
}

// @media screen and (max-width: 768px) {
//   .ant-app {
//     max-width: 100%;
//   }
// }

@media only screen and (max-width: var(--tick-minimum-mobile-width)) {
  .ant-app {
    min-width: var(--tick-minimum-mobile-width); // Default base is 300px;
  }
}
