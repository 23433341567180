.cardItemContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.leftImage {
  height: 100px;
  width: 100px;
}

.middleText {
  flex: 1;
  font-size: 1.5vh;
  height: 100px;
  padding: 0 5px;
}

.upperInfo {
  height: 25%;
}

.middleInfo {
  height: 25%;
  color: var(--ion-color-medium-tint);
}

.rightPrice {
  width: 15%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.price {
  font-size: 1.5vh;
}

.qty {
  color: var(--ion-color-medium-tint);
}