.blockContainer {
  width: 100%;
  height: auto;
  // padding-left: 2.5vw;
  box-sizing: border-box;
  // height: 2.5vw;
  
  .blockTitle {
    font-size: 1.8vh;
    padding: 0.7vh 0 0.7vh 2.0vw;
    background-color: var(--app-background-color);
    color: var(--app-text-secondary-color);
  }

  
  .blockComponent {
    // display: flex;
    // flex-flow: column wrap;
    padding: 1vh 2.0vw 1vh 2.0vw;
    background-color: var(--app-secondary-background-color);
  }
  
  .blockComponent.noPadding {
    padding: 0;
  }
}
