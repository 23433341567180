.uniformSizeItem {
  height: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: var(--app-input-background-color);
  box-shadow: 0px 0 2px var(--adm-color-border);
}

.right {
  display: flex;
  align-items: center;

  .price {
    margin-right: 15px;
    color: var(--adm-color-danger);
    font-weight: bold;
  }

  .selector {
    width: 100px;
    display: flex;
    justify-content: center;

    :global(.adm-list-item-content) {
      border-top: 0;
    }
  }

  :global(.adm-list-item-content-main) {
    padding: 0;
  }
}

